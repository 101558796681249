import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Button, List, Result, Space } from 'antd-mobile'
import { DeleteOutline, EditSOutline } from 'antd-mobile-icons'

import { useStores } from '../../stores';
import { PageContent } from '../../components/PageContent';
import { Spinner } from '../../components/Spinner';
import { UserExamTopicListItem } from '../../components/UserExamTopicListItem';
import { UserExamForm } from '../../components/UserExamForm';
import { CustomNavBar } from '../../components/CustomNavBar';
import './UserExam.css';

export const UserExam = observer(() => {
    const { userExamStore } = useStores();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const { userExamId } = useParams<{ userExamId: string }>();

    useEffect(() => {
        userExamStore.fetchUserExam(userExamId);
    }, [userExamId, userExamStore]);

    if (userExamStore.loading) {
        return <Spinner />
    }

    const userExam = userExamStore.getById(userExamId);
    const subjectTopics = userExam?.topicsBySubject ?? [];

    if (!userExam) {
        return (
            <Result
                status='error'
                title='Error'
                description='Sorry, the page you visited does not exist.'
            />
        )
    }

    const showForm = () => {
        setIsFormOpen(true);
    };

    const hideForm = () => {
        setIsFormOpen(false);
    };

    const handleDelete = () => {

    }

    return (
        <div>
            <CustomNavBar
                title={userExam.exam.name}
                right={
                    <Space>
                        <Button size='small' fill='outline' onClick={showForm}><EditSOutline /></Button>
                        <Button size='small' fill='outline' color='danger' onClick={handleDelete}><DeleteOutline /></Button>
                    </Space>
                }
            />
            <UserExamForm
                userExamId={userExam.id}
                open={isFormOpen}
                onClose={hideForm}
                onCancel={hideForm}
            />

            <PageContent>
                {
                    subjectTopics.map(
                        item => (
                            <List key={item.subject.id} header={item.subject.name}>
                                { item.topics.map(
                                    (topic) => <UserExamTopicListItem key={topic.id} userExamTopic={topic} />
                                )}
                            </List>
                        )
                    )
                }
            </PageContent>
        </div>
    );
});
