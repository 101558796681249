import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import dayjs from 'dayjs';
import ExamModel from './Exam';
import UserExamTopicModel, { UserExamTopic } from './UserExamTopic';
import { Subject } from './Subject';

const UserExamModel = types.model('UserExam', {
    id: types.identifierNumber,
    exam: ExamModel,
    exam_date: types.maybeNull(types.string),
    topics: types.optional(types.array(UserExamTopicModel), []),
}).views((self) => ({
    get examDate() {
        return !!self.exam_date ? dayjs(self.exam_date).format() : 'No exam date yet';
    },

    get topicsBySubject() {
        const groupedTopics = self.topics.reduce((acc, topic) => {
            const subject = topic.topic.subject.name;
            if (!acc[subject]) {
                acc[subject] = {
                    subject: topic.topic.subject,
                    topics: []
                };
            }
            acc[subject].topics.push(topic);
            return acc;
        }, {});

        return Object.values(groupedTopics) as {subject: Subject, topics: UserExamTopic[]}[];
    }
}));
export default UserExamModel;

/**
 * The data of a UserExam.
 */
export interface UserExam extends Instance<typeof UserExamModel> { }
export interface UserExamSnapshotOut extends SnapshotOut<typeof UserExamModel> { }
export interface UserExamSnapshotIn extends SnapshotIn<typeof UserExamModel> { }

