import React from 'react';
import { Button, Space } from 'antd-mobile';
import { Link, useHistory } from 'react-router-dom';

import icon from '../../icon.svg';
import './Header.css';

export const Header: React.FC = () => {
    const history = useHistory();

    const onClick = () => {
        history.push('/login')
    }

    return (
        <div className="header">
            <Space block align='center' justify='between'>
                <div className='icon-container'>
                    <img src={icon} alt='prepped icon' className='icon' />
                </div>
                <Button fill='outline' onClick={onClick}><Link to='/login'>Sign Up / Log In</Link></Button>
            </Space>
        </div>
    );
};
