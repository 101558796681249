import { Instance, SnapshotIn, SnapshotOut, types, flow } from 'mobx-state-tree';
import { fetchPaginatedCards, flagCard, suspendCard, unsuspendCard } from '../api';
import Card from './Card';


const CardStoreModel = types
    .model('CardStore', {
        cards: types.array(Card),
        currentCard: types.optional(types.maybeNull(Card), null),
        currentPage: types.optional(types.number, 1),
        pages: types.optional(types.number, 0),
        totalCards: types.optional(types.number, 0),
    })
    .views((self) => ({
        get hasMore() {
            return self.pages > self.currentPage
        },
    }))
    .actions((self) => ({
        // Fetch cards from the server
        fetchCards: flow(function* () {
            try {
                const response = yield fetchPaginatedCards(self.currentPage);
                const { results, count, page, num_pages } = response.data;

                // Update the store
                self.cards = results;
                self.totalCards = count;
                self.pages = num_pages;
                self.currentPage = page;
            } catch (error) {
                console.error('Failed to fetch cards:', error);
                throw error;
            }
        }),

        // Change the current page
        changePage(newPage) {
            self.currentPage = newPage;
            this.fetchCards();
        },

        suspend: flow(function* (cardId) {
            try {
                yield suspendCard(cardId);
            } catch (error) {
                console.error('Failed to suspend card:', error);
                throw error;
            }
        }),

        unsuspend: flow(function* (cardId) {
            try {
                yield unsuspendCard(cardId);
            } catch (error) {
                console.error('Failed to suspend card:', error);
                throw error;
            }
        }),

        flag: flow(function* (cardId, message) {
            try {
                yield flagCard(cardId, {card: cardId, message});
            } catch (error) {
                console.error('Failed to flag card:', error);
                throw error;
            }
        }),
    }));

export default CardStoreModel;

export interface CardStore extends Instance<typeof CardStoreModel> { }
export interface CardStoreSnapshotIn extends SnapshotIn<typeof CardStoreModel> { }
export interface CardStoreSnapshotOut extends SnapshotOut<typeof CardStoreModel> { }

