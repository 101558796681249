import React, { useEffect } from 'react';
import { List } from 'antd-mobile';
import { observer } from 'mobx-react-lite';

import './Exams.css';
import { useStores } from '../../stores';
import { PageContent } from '../../components/PageContent';
import { Spinner } from '../../components/Spinner';
import { CustomNavBar } from '../../components/CustomNavBar';

export const Exams = observer(() => {
    const { examStore } = useStores();

    useEffect(() => {
        (async function load() {
            await examStore.fetchExams();
        })();
    }, [examStore]);

    return (
        <div>
            <CustomNavBar title='Exams' />
            <PageContent>
                {examStore.loading ? (
                    <Spinner />
                ) : (
                    <List>
                        { examStore.exams.map(
                            (exam) => (
                                <List.Item key={exam.id}>
                                    {exam.name}
                                </List.Item>
                            )
                        )}
                    </List>
                )}
            </PageContent>
        </div>
    );
});
