import React, { useEffect, useState } from 'react';
import { InfiniteScroll, List } from 'antd-mobile'
import { observer } from 'mobx-react-lite';

import './Decks.css';
import { useStores } from '../../stores';
import { Deck } from '../../stores/Deck';
import { PageContent } from '../../components/PageContent';
import { Spinner } from '../../components/Spinner';
import { DeckListItem } from '../../components/DeckListItem';
import { CustomNavBar } from '../../components/CustomNavBar';

export const Decks = observer(() => {
    const { deckStore } = useStores();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchDecks();
    }, [deckStore]);

    const fetchDecks = async () => {
        if (isLoading) return
        setIsLoading(true);
        try {
            await deckStore.fetchDecks();
        } catch (err) {
            console.debug({ err });
        } finally {
            setIsLoading(false);
        }
    }

    const loadMore = async () => {
        if (isLoading || !deckStore.hasMore) return
        try {
            await deckStore.changePage(deckStore.currentPage + 1);
        } catch (err) {
            console.debug({ err });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <CustomNavBar title='Decks' userExamCTA />

            <PageContent>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <List>
                            { deckStore.decks.map(
                                (deck: Deck) => <DeckListItem key={deck.id} deck={deck} />
                            )}
                        </List>
                        <InfiniteScroll loadMore={loadMore} hasMore={deckStore.hasMore} />
                    </>
                )}
            </PageContent>
        </div>
    );
});
