import { Instance, SnapshotIn, SnapshotOut, types, flow } from 'mobx-state-tree';
import { fetchAllExams } from '../api';
import Exam from './Exam';


const ExamStoreModel = types
    .model('ExamStore', {
        exams: types.array(Exam),
        loading: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        // Fetch exams from the server
        fetchExams: flow(function* () {
            if (self.loading) return;

            self.loading = true;
            try {
                const response = yield fetchAllExams();

                // Update the store
                self.exams = response.data;
            } catch (error) {
                console.error('Failed to fetch exams:', error);
                throw error;
            } finally {
                self.loading = false;
            }
        }),
    }));

export default ExamStoreModel;

export interface ExamStore extends Instance<typeof ExamStoreModel> { }
export interface ExamStoreSnapshotIn extends SnapshotIn<typeof ExamStoreModel> { }
export interface ExamStoreSnapshotOut extends SnapshotOut<typeof ExamStoreModel> { }

