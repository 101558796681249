import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import SubjectModel from './Subject';

const ExamModel = types.model('Exam', {
    id: types.identifierNumber,
    name: types.string,
    subjects: types.array(SubjectModel)
});

export default ExamModel;

/**
 * The data of a Exam.
 */
export interface Exam extends Instance<typeof ExamModel> { }
export interface ExamSnapshotOut extends SnapshotOut<typeof ExamModel> { }
export interface ExamSnapshotIn extends SnapshotIn<typeof ExamModel> { }

