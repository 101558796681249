import React from 'react';
import { Bundles } from './Bundles';
import { Features } from './Features';
import { Hero } from './Hero';
import './Content.css';

export const Content: React.FC = () => {
    return (
        <div className="content-container">
            <Hero />
            <Bundles />
            <Features />
        </div>
    );
};
