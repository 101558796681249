import { types, flow } from 'mobx-state-tree';
import type { Dayjs } from 'dayjs';
import { fetchStatistic } from '../api';

const StatisticStoreModel = types
    .model({
        due_cards_count: types.optional(types.number, 0),
        new_cards_count: types.optional(types.number, 0),
        attempted_cards_count: types.optional(types.number, 0),
        studied: types.optional(types.map(types.number), {}),
        to_study: types.optional(types.map(types.number), {}),
    })
    .actions((self) => ({
        fetch: flow(function* () {
            try {
                const response = yield fetchStatistic();
                self.due_cards_count = response.data.due_cards_count;
                self.new_cards_count = response.data.new_cards_count;
                self.attempted_cards_count = response.data.attempted_cards_count;
                self.studied = response.data.daily_statistics.studied;
                self.to_study = response.data.daily_statistics.to_study;
            } catch (error) {
                console.error('Failed to fetch statistic:', error);
                throw error;
            }
        }),

        getDailyStat(day: Dayjs) {
            console.log({ studied: self.studied })
            const key = day.format('YYYY-MM-DD');
            return {
                studied: self.studied.get(key) || 0,
                toStudy: self.to_study.get(key) || 0
            }
        }
    }));

export default StatisticStoreModel;
