import React, { useEffect, useState } from 'react';
import { InfiniteScroll, List } from 'antd-mobile'
import { observer } from 'mobx-react-lite';

import { useStores } from '../../stores';
import { Card } from '../../stores/Card';
import { PageContent } from '../../components/PageContent';
import { Spinner } from '../../components/Spinner';
import { CardListItem } from '../../components/CardListItem';
import { CustomNavBar } from '../../components/CustomNavBar';

export const Cards = observer(() => {
    const { cardStore } = useStores();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchCards();
    }, [cardStore]);

    const fetchCards = async () => {
        if (isLoading) return
        setIsLoading(true);
        try {
            await cardStore.fetchCards();
        } catch (err) {
            console.debug({ err });
        } finally {
            setIsLoading(false);
        }
    }

    const loadMore = async () => {
        if (isLoading || !cardStore.hasMore) return
        try {
            await cardStore.changePage(cardStore.currentPage + 1);
        } catch (err) {
            console.debug({ err });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <CustomNavBar title='Cards' userExamCTA />

            <PageContent>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <List>
                            { cardStore.cards.map(
                                (card: Card) => <CardListItem key={card.id} card={card} />
                            )}
                        </List>
                        <InfiniteScroll loadMore={loadMore} hasMore={cardStore.hasMore} />
                    </>
                )}
            </PageContent>
        </div>
    );
});
