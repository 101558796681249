import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams, Link } from 'react-router-dom';
import { ActionSheet, Empty, List, Button, Result } from 'antd-mobile';
import { MoreOutline } from 'antd-mobile-icons'
import type { Action } from 'antd-mobile/es/components/action-sheet'
  
import { useStores } from '../../stores';
import { Card } from '../../stores/Card';
import { CardListItem } from '../../components/CardListItem';
import { CustomNavBar } from '../../components/CustomNavBar';
import { PageContent } from '../../components/PageContent';
import { Spinner } from '../../components/Spinner';
import './Deck.css';

export const Deck = observer(() => {
    const { deckStore, studyStore } = useStores();
    const { deckId } = useParams<{ deckId: string }>();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [actionSheetVisible, setActionSheetVisible] = useState<boolean>(false);

    useEffect(() => {
        fetchDeck();
    }, [deckId, deckStore]);

    const deck = deckStore.getDeckById(deckId);
    const actions = useMemo(() => {
        let data: Action[] = [];
        if (!deck?.isEmpty) {
            data.push({
                key: 'study',
                text: 'Study Cards'
            })
        }

        if (deck?.is_owner) {
            data.push({
                key: 'manage',
                text: 'Manage Cards'
            })
        }

        return data;
    }, [deck?.isEmpty, deck?.is_owner])

    if (!deck && isLoading) {
        return (
            <Spinner />
        );
    }

    const fetchDeck = async () => {
        if (isLoading) return
        setIsLoading(true);
        try {
            await deckStore.fetchSingleDeck(deckId);
        } catch (err) {
            console.debug({ err });
        } finally {
            setIsLoading(false);
        }
    }

    const handleStudy = () => {
        studyStore.setQuery({
            type: 'new_due',
            deck: deck!.id
        });
        history.push('/study');
    }

    const handleManage = () => {
        history.push(`/deck/${deck!.id}/manage-cards`);
    }

    const handleAction = action => {
        setActionSheetVisible(false);
        switch (action.key) {
            case 'study':
                return handleStudy();
            case 'manage':
                return handleManage();
            default:
                break;
        }
    }

    if (!deck) {
        return (
            <Result
                status='error'
                title='Error'
                description='Sorry, the page you visited does not exist.'
            />
        )
    }

    return (
        <div>
            <CustomNavBar
                title={deck.title}
                right={actions.length === 0 ? null : <Button size='small' fill='outline' color='primary' onClick={() => setActionSheetVisible(true)}><MoreOutline /></Button>}
            />
            <PageContent>
                {
                    deck.cards.length === 0
                        ? (
                            <Empty
                                description={
                                    <Link to={`/deck/${deck.id}/manage-cards`}>
                                        <Button color='primary'>Add Flashcards</Button>
                                    </Link>
                                }
                            />
                        )
                        : (
                            <List>
                                { deck.cards.map(
                                    (card: Card) => <CardListItem key={card.id} card={card} />
                                )}
                            </List>
                        )
                }
            </PageContent>
            <ActionSheet
                visible={actionSheetVisible}
                actions={actions}
                onAction={handleAction}
                onClose={() => setActionSheetVisible(false)}
            />
        </div>
    );
});
