import React, { useEffect, useState } from 'react';
import { List } from 'antd-mobile'
import { observer } from 'mobx-react-lite';

import { useStores } from '../../stores';
import { PageContent } from '../../components/PageContent';
import { Spinner } from '../../components/Spinner';
import { UserExamTopicListItem } from '../../components/UserExamTopicListItem';
import { CustomNavBar } from '../../components/CustomNavBar';

export const Topics = observer(() => {
    const { userExamStore } = useStores();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const subjectTopics = userExamStore.current?.topicsBySubject ?? [];

    useEffect(() => {
        fetchCurrentUserExam();
    }, [userExamStore]);

    const fetchCurrentUserExam = async () => {
        if (isLoading) return
        try {
            await userExamStore.fetchUserExam(userExamStore.currentId);
        } catch (err) {
            console.debug({ err });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <CustomNavBar title='Topics' userExamCTA />

            <PageContent>
                {isLoading ? (
                    <Spinner />
                ) : (
                    subjectTopics.map(
                        item => (
                            <List key={item.subject.id} header={item.subject.name}>
                                { item.topics.map(
                                    (topic) => <UserExamTopicListItem key={topic.id} userExamTopic={topic} />
                                )}
                            </List>
                        )
                    )
                )}
            </PageContent>
        </div>
    );
});
