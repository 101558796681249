import { Instance, SnapshotIn, SnapshotOut, types, flow } from 'mobx-state-tree';
import { fetchAllSubjects } from '../api';
import Subject from './Subject';


const SubjectStoreModel = types
    .model('SubjectStore', {
        subjects: types.array(Subject),
        loading: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        // Fetch subjects from the server
        fetchSubjects: flow(function* () {
            if (self.loading) return;

            self.loading = true;
            try {
                const response = yield fetchAllSubjects();

                // Update the store
                self.subjects = response.data;
            } catch (error) {
                console.error('Failed to fetch subjects:', error);
                throw error;
            } finally {
                self.loading = false;
            }
        }),
    }));

export default SubjectStoreModel;

export interface SubjectStore extends Instance<typeof SubjectStoreModel> { }
export interface SubjectStoreSnapshotIn extends SnapshotIn<typeof SubjectStoreModel> { }
export interface SubjectStoreSnapshotOut extends SnapshotOut<typeof SubjectStoreModel> { }

