// CustomNavBar.tsx
import React from 'react';
import { NavBar } from 'antd-mobile'
import { useHistory } from 'react-router-dom';

import { UserExamCTA } from '../../components/UserExamCTA';
import './CustomNavBar.css';

interface CustomNavBarProps {
    title: string;
    right?: React.ReactNode;
    userExamCTA?: boolean;
}

export const CustomNavBar: React.FC<CustomNavBarProps> = ({ title, right, userExamCTA }) => {
    const history = useHistory();

    return (
        <div>
            <NavBar onBack={history.goBack} right={right}>{title}</NavBar>
            {userExamCTA && <UserExamCTA /> }
        </div>
    );
};
