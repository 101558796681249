import { Instance, SnapshotIn, SnapshotOut, types, flow } from 'mobx-state-tree';
import dayjs from 'dayjs';

import CardModel from './Card';
import { fetchStudyCards, saveStudy } from '../api'

const BLACKOUT = 0;
const INCORRECT = 1;
const CORRECT = 2;
const EASY = 4;

const StudyQueryModel = types.model('StudyQuery', {
    type: types.enumeration('Type', ['random', 'new', 'due', 'new_due']),
    deck: types.maybeNull(types.number),
    limit: types.maybeNull(types.number),
});
export interface StudyQuery extends Instance<typeof StudyQueryModel> { }

const StudyAttemptModel = types.model('StudyAttempt', {
    card: types.identifier,
    duration: types.number,
    recall_level: types.number,
    attempt_time: types.maybeNull(types.string),
})
const CardFlagModel = types.model('CardFlag', {
    card: types.number,
    message: types.optional(types.string, ''),
});

const StudyStoreModel = types
    .model('StudyStore', {
        query: types.maybe(StudyQueryModel),
        cards: types.optional(types.array(CardModel), []),
        current: types.optional(types.number, 0),
        flipped: types.optional(types.boolean, false),
        loading: types.optional(types.boolean, false),
        attempts: types.optional(types.map(StudyAttemptModel), {}),
        suspended: types.optional(types.array(types.number), []),
        flags: types.optional(types.array(CardFlagModel), []),
        startTime: types.optional(types.maybeNull(types.Date), null),
        endTime: types.optional(types.maybeNull(types.Date), null),
        stopped: types.optional(types.boolean, false),
    })
    .views((self: Instance<typeof StudyStoreModel>) => ({

        get activeCards() {
            return self.cards.filter(card => !self.suspended.includes(card.id));
        },

        get isEmpty() {
            return self.cards.length === 0;
        },

        get isCompleted() {
            return self.current >= self.cards.length
        },

        get currentCard() {
            return self.current < self.cards.length ? self.cards[self.current] : null;
        },

        get progress() {
            const total = self.cards.length;
            const count = self.current;
            return total === 0 ? 0 : Math.round((count / total) * 100);
        },

        get isNextDisabled() {
            return self.current >= self.cards.length - 1;
        },

        get isPrevDisabled() {
            return self.current <= 0;
        },

        get totalTimeSpent() {
            let totalSeconds = Array
                .from(self.attempts.values() as Instance<typeof StudyAttemptModel>[])
                .map((attempt: Instance<typeof StudyAttemptModel>) => attempt.duration)
                .reduce((acc, cur) => acc + cur, 0);
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = Math.ceil(totalSeconds % 60);
            return `${hours}h ${minutes}m ${seconds}s`;
        },

        get totalCardsAnswered() {
            return self.attempts.size;
        },

        get recallLevelCount() {
            const recallCounts: { [key: number]: number } = {};
            Array
                .from(self.attempts.values() as Instance<typeof StudyAttemptModel>[])
                .forEach((attempt: Instance<typeof StudyAttemptModel>) => {
                if (recallCounts[attempt.recall_level] === undefined) {
                    recallCounts[attempt.recall_level] = 0;
                }
                recallCounts[attempt.recall_level] += 1;
            });
            return recallCounts;
        },

        get recallLevels() {
            const levels = self.recallLevelCount;

            return {
                'blackout': levels[BLACKOUT] || 0,
                'incorrect': levels[INCORRECT] || 0,
                'correct': levels[CORRECT] || 0,
                'easy': levels[EASY] || 0,
            }
        },

        get title() {
            return `${self.current + 1} of ${self.cards.length}`
        },

        get canResume() {
            return self.stopped && !self.isCompleted;
        },
    }))
    .actions((self: Instance<typeof StudyStoreModel>) => ({
        setQuery (query: StudyQuery) {
            self.query = query
        },

        reset() {
            self.cards.clear();
            self.attempts.clear();
            self.flags.clear();
            self.suspended.clear();
            self.current = 0;
            self.flipped = false;
            self.startTime = null;
            self.endTime = null;
            self.stopped = false;
        },

        fetchCards: flow(function* () {
            if (self.loading) return;

            self.loading = true;
            try {
                const response = yield fetchStudyCards({
                    type: self.query?.type,
                    deck: self.query?.deck,
                    limit: self.query?.limit,
                });

                self.cards = response.data;
                self.current = 0;
                self.flipped = false;
                self.startTime = new Date();
                self.endTime = null;
                self.stopped = false;
            } catch (error) {
                console.error('Failed to fetch cards to study:', error);
                throw error;
            } finally {
                self.loading = false;
            }
        }),

        save: flow(function* () {
            try {
                yield saveStudy({
                    attempts: Array.from(self.attempts.values()),
                    flags: self.flags,
                    suspended: self.suspended
                });
                self.reset();
            } catch (error) {
                console.error('Failed to save study session:', error);
                throw error;
            }
        }),

        flip() {
            self.flipped = !self.flipped;
            if (self.flipped && !self.endTime) {
                self.endTime = new Date();
            }

        },

        rate(recall_level: number) {
            console.log({ recall_level })

            if (!self.currentCard) {
                return
            }

            if (self.startTime && self.endTime) {
                const duration = Math.ceil((self.endTime.getTime() - self.startTime.getTime()) / 1000); // in seconds
                const card = self.currentCard.id.toString();

                self.attempts.set(card, { card, duration, recall_level, attempt_time: dayjs(self.startTime).format() });

                self.next();
            }
        },

        rateBlackout() {
            self.rate(BLACKOUT);
        },

        rateIncorrect() {
            self.rate(INCORRECT);
        },

        rateCorrect() {
            self.rate(CORRECT);
        },

        rateEasy() {
            self.rate(EASY);
        },

        previous() {
            if (self.current > 0) {
                self.flipped = false;
                self.current -= 1;
            }
        },

        next() {
            if (self.current < self.cards.length) {
                self.flipped = false;
                self.current += 1;
                self.startTime = new Date();
                self.endTime = null;
            } else {
                self.stopped = true;
            }
        },

        stop() {
            self.stopped = true;
        },

        resume() {
            self.stopped = false;
        },

        suspendCurrentCard() {
            if (self.currentCard) {
                self.suspended.push(self.currentCard.id);
                self.next();
            }
        },

        unsuspendCard(cardId: number) {
            const index = self.suspended.indexOf(cardId);
            if (index !== -1) {
                self.suspended.splice(index, 1);
            }
        },

        addFlag(card: number, message: string = '') {
            self.flags.push({ card, message });
        },

        removeFlag(card: number) {
            const index = self.flags.findIndex(flag => flag.card === card);
            if (index !== -1) {
                self.flags.splice(index, 1);
            }
        },

        updateFlag(card: number, message: string) {
            const flag = self.flags.find(f => f.card === card);
            if (flag) {
                flag.message = message;
            }
        },

    }));

export default StudyStoreModel;

export interface StudyStore extends Instance<typeof StudyStoreModel> { }
export interface StudyStoreSnapshotIn extends SnapshotIn<typeof StudyStoreModel> { }
export interface StudyStoreSnapshotOut extends SnapshotOut<typeof StudyStoreModel> { }
