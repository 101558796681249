import { Instance, SnapshotIn, SnapshotOut, types, flow } from 'mobx-state-tree';
import { login, logout, refreshToken } from '../api';
import User, { UserSnapshot } from './User';
import { getRootStore } from './helpers/getRootStore';

const AuthStoreModel = types
    .model({
        token: types.optional(types.maybeNull(types.string), null),
        refreshToken: types.optional(types.maybeNull(types.string), null),
        user: types.optional(types.maybeNull(User), null),
    })
    .views((self) => ({
        get isAuthenticated() {
            return self.token !== null;
        },
    }))
    .actions((self) => ({
        setAuthData(token: string, user: UserSnapshot) {
            self.token = token;
            // Assuming you have a `self.user` to set.
            self.user = user;
        },
        login: flow(function* (username: string, password: string) {
            try {
                const response = yield login(username, password);
                // Assuming the API returns a token and user details on successful registration
                const { access_token, user } = response.data;
                console.log({ access_token, user })
                const rootStore = getRootStore(self);
                rootStore.authStore.setAuthData(access_token, {
                    id: user.id,
                    email: user.email,
                    firstName: user.first_name,
                    lastName: user.last_name,
                });
            } catch (error) {
                console.error('Failed to login', error);
                throw error; // Propagate error
            }
        }),
        logout: flow(function* () {
            try {
                yield logout();
            } catch (error) {
                console.error('Failed to logout', error);
            } finally {
                self.token = null;
                self.refreshToken = null;
                self.user = null;
            }
        }),
        refreshAuthToken: flow(function* () {
            if (self.refreshToken === null) {
                console.error("Refresh token is null, cannot refresh.");
                return;
            }
            try {
                const response = yield refreshToken(self.refreshToken);
                self.token = response.access_token;
                self.refreshToken = response.refresh_token;
            } catch (error) {
                console.error('Failed to refresh token', error);
                throw error; // Propagate error
            }
        }),
    }));

export default AuthStoreModel;

export interface AuthStore extends Instance<typeof AuthStoreModel> { }
export interface AuthStoreSnapshotIn extends SnapshotIn<typeof AuthStoreModel> { }
export interface AuthStoreSnapshotOut extends SnapshotOut<typeof AuthStoreModel> { }

