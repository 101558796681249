import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import parse from 'html-react-parser';


const ContentModel = types.model('Content', {
    text: types.string,
    image: types.maybeNull(types.string),
}).views((self) => ({
    get parsedText() {
        return parse(self.text);
    },
}));


const UserCardModel = types.model('UserCard', {
    scheduling_algorithm: types.maybeNull(types.string),
    ease_factor: types.maybeNull(types.number),
    interval: types.maybeNull(types.number),
    repetitions: types.maybeNull(types.number),
    lapse_count: types.maybeNull(types.number),
    next_review_date: types.maybeNull(types.string),
    review_time: types.maybeNull(types.string),
    learning_step: types.maybeNull(types.number),
});


const CardAttemptModel = types.model('CardAttempt', {
    recall_level: types.maybeNull(types.number),
    attempt_time: types.maybeNull(types.string),
    duration: types.maybeNull(types.number),
});


const CardDeck = types.model('CardDeck', {
    id: types.identifierNumber,
    title: types.string,
});


const CardModel = types.model('Card', {
    id: types.identifierNumber,
    question: ContentModel,
    answer: ContentModel,
    ordering: types.number,
    hint: types.maybeNull(types.string),
    deck: CardDeck,
    is_due: types.optional(types.boolean, false),
    is_suspended: types.optional(types.boolean, false),
    deck_is_suspended: types.optional(types.boolean, false),
    user_card: types.maybeNull(UserCardModel),
    attempts: types.array(CardAttemptModel),
}).actions((self) => ({
    suspend() {
        self.is_suspended = true;
    },
    unsuspend() {
        self.is_suspended = false;
    }
}));

export default CardModel;

/**
 * The data of a Card.
 */
export interface Card extends Instance<typeof CardModel> { }
export interface CardSnapshotOut extends SnapshotOut<typeof CardModel> { }
export interface CardSnapshotIn extends SnapshotIn<typeof CardModel> { }

export interface ContentInputData {
    text: string;
    image?: string | File | null;
};

export interface CardInputData {
    id?: number;
    question?: ContentInputData;
    answer?: ContentInputData;
    ordering?: number;
    hint?: string | null;
    cid: string;
}