import React from 'react';
import './PageContent.css';

interface PageContentProps {
    children: React.ReactNode;
}

export const PageContent: React.FC<PageContentProps> = ({ children }) => (
    <div>
        {children}
    </div>
)
 
