import React from 'react';
import { List, Space } from 'antd-mobile';
import { TagOutline } from 'antd-mobile-icons'
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import * as UserExamTopicModel from '../../stores/UserExamTopic';
import { IconText } from '../IconText';
import './UserExamTopicListItem.css';

interface UserExamTopicListItemProps {
    userExamTopic: UserExamTopicModel.UserExamTopic;
}

export const UserExamTopicListItem: React.FC<UserExamTopicListItemProps> = observer(({ userExamTopic }) => {
    const history = useHistory();


    const handleClick = (e) => history.push(`/exam-topic/${userExamTopic.id}`);

    return (
        <List.Item
            description={
                <Space justify='start' block style={{ '--gap': '24px' }}>
                    <IconText icon={TagOutline} text={`Mastery: ${userExamTopic.mastery_level}`} key='mastery-level' />
                    <IconText icon={TagOutline} text={`Importance: ${userExamTopic.importance}`} key='importance' />
                </Space>
            }
            onClick={handleClick}
            arrow
        >
            {userExamTopic.topic.name}
        </List.Item>
    )
});
