// StudyModal.tsx
import React, { useState } from 'react';
import { Modal, Radio } from 'antd';

import './StudyModal.css'; // Import the stylesheet

interface Props {
    title: string;
    open: boolean;
    dueCardsCount: number,
    newCardsCount: number,
    onClose: () => void;
    onStudy: (query) => void;
}

export const StudyModal: React.FC<Props> = ({ title, open, dueCardsCount, newCardsCount, onClose, onStudy }) => {
    const [studyType, setStudyType] = useState<string | null>(null);

    return (
        <Modal
            title={title}
            open={open}
            centered
            onCancel={onClose}
            footer={null}  // Removing footer
            width={640}
        >
            <Radio.Group
                className='study-options'
                onChange={(e) => setStudyType(e.target.value)}
                value={studyType}
            >
                <Radio.Button className='study-option' value='random'>Quick Study</Radio.Button>
                <Radio.Button className='study-option' disabled={dueCardsCount == 0} value='due'>{`Due Cards \n (${dueCardsCount})`}</Radio.Button>
                <Radio.Button className='study-option' disabled={newCardsCount == 0} value='new'>{`New Cards (${newCardsCount})`}</Radio.Button>
                <Radio.Button className='study-option' value='new_due'>All Cards</Radio.Button>
            </Radio.Group>
            <div className='start-study-footer'>  {/* Use this class to center the button */}
                <button
                    className='start-study-btn'
                    disabled={!studyType}
                    onClick={() => {
                        onStudy({type: studyType});
                    }}
                >
                    Start Study
                </button>
            </div>
        </Modal>
    );
};
