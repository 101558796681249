import React, { useState, useEffect, useMemo } from 'react';
import { TabBar } from 'antd-mobile'
import {
    AppOutline,
    ContentOutline,
    FolderOutline,
    UserOutline,
  } from 'antd-mobile-icons'
  
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../stores';
import { StudyQuery } from '../../stores/StudyStore';
import { useStudyButton } from '../../context/StudyButtonContext';
import { StudyModal } from '../../components/StudyModal';
import './AuthLayout.css';

interface AuthLayoutProps {
    children: React.ReactNode;
}

const MenuBar = () => {
    const location = useLocation();
    const history = useHistory();
    const hideMenuBar = useMemo(() => {
        return location?.pathname?.endsWith('/study');
    }, [location?.pathname])

    const selectedKeys = useMemo(() => {
        if (location?.pathname?.startsWith('/deck')) {
            return ['decks']
        } else if (location?.pathname?.startsWith('/card')) {
            return ['cards']
        } else if (location?.pathname?.startsWith('/topics') || location?.pathname?.startsWith('/user-exam')) {
            return ['topics']
        } else if (location?.pathname?.startsWith('/account')) {
            return []
        } else {
            return ['dashboard']
        }
    }, [location?.pathname])

    const handleChange = (value) => {
        history.push(`/${value}`);
    }

    console.log({hideMenuBar, pathName: location?.pathname})

    if (hideMenuBar) {
        return null;
    }

    return (
        <TabBar safeArea onChange={handleChange}>
            <TabBar.Item
              key='dashboard'
              icon={<AppOutline />}
              title='Dashboard'
            />
            <TabBar.Item
              key='topics'
              icon={<ContentOutline />}
              title='Topics'
            />
            <TabBar.Item
              key='decks'
              icon={<FolderOutline />}
              title='Decks'
            />
            <TabBar.Item
              key='account'
              icon={<UserOutline />}
              title='Account'
            />
        </TabBar>
    )
};

export const AuthLayout: React.FC<AuthLayoutProps> = observer(({ children }) => {
    const history = useHistory();
    const { userExamStore, statisticStore, studyStore } = useStores();
    const [isStudyModalOpen, setIsStudyModalOpen] = useState(false);
    const { isButtonVisible, showButton, hideButton } = useStudyButton();

    useEffect(() => {
        userExamStore.reset();
        return () => {
            userExamStore.reset();
        };
    }, [userExamStore]);

    useEffect(() => {
        (async function load() {
            await userExamStore.fetch();
        })();
    }, [userExamStore]);

    useEffect(() => {
        if (isStudyModalOpen) {
            hideButton();
        } else {
            showButton();
        }
    }, [isStudyModalOpen]);

    const showStudyModal = () => {
        setIsStudyModalOpen(true);
    };

    const hideStudyModal = () => {
        setIsStudyModalOpen(false);
    };

    const handleStudy = (query: StudyQuery) => {
        studyStore.setQuery(query);
        hideStudyModal();
        history.push('/study');
    };

    return (
        <div className='page-container'>
            {children}
            <StudyModal
                title='Study'
                open={isStudyModalOpen}
                onClose={hideStudyModal}
                onStudy={(query) => handleStudy(query)}
                dueCardsCount={statisticStore.due_cards_count}
                newCardsCount={statisticStore.new_cards_count}
            />
            <div className='page-container-menubar'>
                <MenuBar />
            </div>
        </div>
    )
});
