import React, { useEffect, useState } from 'react';
import { message, Modal, Form, Select, DatePicker } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../stores';
import './UserExamForm.css';
import { toDate } from '../../utils/date';

interface Props {
    userExamId?: number,
    open: boolean;
    onClose: () => void;
    onCancel?: () => void;
}

export const UserExamForm: React.FC<Props> = observer(({ userExamId, open, onClose, onCancel }) => {
    const [messageApi] = message.useMessage();
    const { examStore, userExamStore } = useStores();
    const userExam = userExamId !== undefined ? userExamStore.getById(userExamId) : null;
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const hasNoExam = onCancel === undefined

    useEffect(() => {
        (async function load() {
            await examStore.fetchExams();
        })();
    }, [examStore]);

    const handleSave = async (data) => {
        setIsLoading(true);
        try {
            await userExamStore.save(userExam?.id, data);
            messageApi.success('Exam data saved successfully');
            onClose();
        } catch (error) {
            messageApi.error('Failed to save exam data');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            title={userExam ? 'Edit' : 'Add Exam'}
            open={open}
            centered
            onCancel={onCancel}
            cancelText={''}
            width={640}
            maskClosable={!hasNoExam}
            okButtonProps={{ loading: isLoading }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleSave(values);
                    })
                    .catch(info => {
                        console.log('Validation failed:', info);
                    });
            }}
            closeIcon={hasNoExam ? null : <CloseOutlined />}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    exam: userExam?.exam?.id,
                    exam_date: toDate(userExam?.exam_date)
                }}
            >
                <Form.Item
                    label="Exam"
                    name="exam"
                    rules={[{ required: true, message: 'Please select an exam' }]}
                >
                    <Select>
                        {examStore.exams.map(exam => (
                            <Select.Option key={exam.id} value={exam.id}>
                                {exam.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Exam Date"
                    name="exam_date"
                >
                    <DatePicker />
                </Form.Item>
            </Form>
        </Modal>
    );
});
