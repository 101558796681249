import { Instance, SnapshotIn, SnapshotOut, types, flow } from 'mobx-state-tree';
import {
    createDeck, fetchDeckById, fetchPaginatedDecks, updateDeckCards,
    suspendDeck, unsuspendDeck
} from '../api';
import Deck from './Deck';


const DeckStoreModel = types
    .model('DeckStore', {
        decks: types.array(Deck),
        currentDeck: types.optional(types.maybeNull(Deck), null),
        currentPage: types.optional(types.number, 1),
        totalDecks: types.optional(types.number, 0),
        pages: types.optional(types.number, 0),
        loading: types.optional(types.boolean, false),
    })
    .views((self) => ({
        get hasMore() {
            return self.pages > self.currentPage
        },
    }))
    .actions((self) => ({
        fetchDecks: flow(function* () {
            try {
                const response = yield fetchPaginatedDecks(self.currentPage);
                const { results, count, num_pages, page } = response.data;

                // Update the store
                self.decks = results;
                self.totalDecks = count;
                self.pages = num_pages;
                self.currentPage = page;
            } catch (error) {
                console.error('Failed to fetch decks:', error);
                throw error;
            }
        }),

        // Change the current page
        changePage(newPage) {
            self.currentPage = newPage;
            this.fetchDecks();
        },

        // Create a new deck
        createDeck: flow(function* (formData) {
            try {
                const response = yield createDeck(formData);
                // Optionally, you can add the newly created deck to self.decks
                self.decks.push(response.data);
                return response.data;
            } catch (error) {
                console.error('Failed to create deck:', error);
                throw error;
            }
        }),

        fetchSingleDeck: flow(function* (deckId) {
            try {
                const response = yield fetchDeckById(deckId);
                self.currentDeck = response.data;
            } catch (error) {
                console.error('Failed to fetch deck:', error);
                throw error;
            }
        }),

        getDeckById(deckId) {
            return self.decks.find(deck => deck.id === deckId) || self.currentDeck;
        },

        saveCardsForDeck: flow(function* (deckId, formData) {
            try {
                const response = yield updateDeckCards(deckId, formData);

                // const updatedDeck = this.getDeckById(deckId);
                // if (updatedDeck) {
                //     updatedDeck.cards = response.data;
                // }

                return response.data;
            } catch (error) {
                console.error(`Failed to save cards for deck ${deckId}:`, error);
                throw error;
            }
        }),

        suspend: flow(function* (deckId) {
            try {
                yield suspendDeck(deckId);
            } catch (error) {
                console.error('Failed to suspend deck:', error);
                throw error;
            }
        }),

        unsuspend: flow(function* (deckId) {
            try {
                yield unsuspendDeck(deckId);
            } catch (error) {
                console.error('Failed to suspend deck:', error);
                throw error;
            }
        }),

    }));

export default DeckStoreModel;

export interface DeckStore extends Instance<typeof DeckStoreModel> { }
export interface DeckStoreSnapshotIn extends SnapshotIn<typeof DeckStoreModel> { }
export interface DeckStoreSnapshotOut extends SnapshotOut<typeof DeckStoreModel> { }

