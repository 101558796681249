import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

const SubjectModel = types.model('Subject', {
    id: types.identifierNumber,
    name: types.string,
});

export default SubjectModel;

/**
 * The data of a Subject.
 */
export interface Subject extends Instance<typeof SubjectModel> { }
export interface SubjectSnapshotOut extends SnapshotOut<typeof SubjectModel> { }
export interface SubjectSnapshotIn extends SnapshotIn<typeof SubjectModel> { }

