import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthLayout } from './AuthLayout';
import { StudyButtonProvider } from '../context/StudyButtonContext';

interface ProtectedRouteProps extends RouteProps {
    isAuthenticated: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    isAuthenticated,
    ...routeProps
}) => {
    return isAuthenticated ? (
        <StudyButtonProvider>
            <AuthLayout>
                <Route {...routeProps} />
            </AuthLayout>
        </StudyButtonProvider>
    ) : (
        <Redirect to='/login' />
    );
};

export default ProtectedRoute;
