import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import CardModel from './Card';

const DeckModel = types.model('Deck', {
    id: types.identifierNumber,
    title: types.string,
    description: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    card_generation_status: types.maybeNull(types.string),
    is_suspended: types.maybeNull(types.boolean),
    is_owner: types.maybeNull(types.boolean),
    is_public: types.maybeNull(types.boolean),
    due_cards_count: types.optional(types.number, 0),
    modified_at: types.maybeNull(types.string),
    created_at: types.maybeNull(types.string),
    cards: types.array(CardModel)
}).views((self) => ({
    get isEmpty() {
        return self.cards.length === 0;
    },

    get count() {
        return self.cards.length;
    },

    get cardCountDescription() {
        const count = self.cards.length;
        return `${count} ${count === 1 ? 'card' : 'card'}`;
    },
})).actions((self) => ({
    suspend() {
        self.is_suspended = true;
    }, 
    unsuspend() {
        self.is_suspended = false;
    }
}));

export default DeckModel;

/**
 * The data of a Deck.
 */
export interface Deck extends Instance<typeof DeckModel> { }
export interface DeckSnapshotOut extends SnapshotOut<typeof DeckModel> { }
export interface DeckSnapshotIn extends SnapshotIn<typeof DeckModel> { }

