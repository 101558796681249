import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import SubjectModel from './Subject';

const TopicModel = types.model('Topic', {
    id: types.identifierNumber,
    name: types.string,
    subject: SubjectModel,
});

export default TopicModel;

/**
 * The data of a Topic.
 */
export interface Topic extends Instance<typeof TopicModel> { }
export interface TopicSnapshotOut extends SnapshotOut<typeof TopicModel> { }
export interface TopicSnapshotIn extends SnapshotIn<typeof TopicModel> { }

