import React from 'react';
import { Space } from 'antd-mobile';

import './IconText.css';

interface IconTextProps {
    icon: React.FC;
    text: string;
}

export const IconText: React.FC<IconTextProps> = ({ icon, text }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
);