import * as storage from "../../storage";
import { ROOT_STATE_STORAGE_KEY } from './setupRootStore';

interface RestoredState {
    authStore?: {
        token?: string;
    };
    userExamStore?: {
        current?: {
            exam?: {
                id?: number
            }
        }
    }
}

export const getRestoredState = async (): Promise<RestoredState> => {
    return ((await storage.load(ROOT_STATE_STORAGE_KEY)) ?? {}) as RestoredState
};

export const getAuthToken = async (): Promise<string | undefined> => {
    let token: string | undefined;

    try {
        const restoredState = await getRestoredState();
        console.log({ restoredState });
        token = restoredState.authStore?.token;
    } catch (e) {
        // if there's any problems loading, then inform the dev what happened
        console.error(e.message, null);
    }

    return token;
};


export const getActiveExamId = async (): Promise<number | undefined> => {
    let examId: number | undefined;

    try {
        const restoredState = await getRestoredState();
        console.log({ restoredState });
        examId = restoredState.userExamStore?.current?.exam?.id;
    } catch (e) {
        // if there's any problems loading, then inform the dev what happened
        console.error(e.message, null);
    }

    return examId;
};