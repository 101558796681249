import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, Link } from 'react-router-dom';
import { Form, Input, Button, NavBar, NoticeBar } from 'antd-mobile';

import { useStores } from '../../stores';
import './Register.css';

export const Register = observer(() => {
    const history = useHistory(); // if using react-router
    const [step, setStep] = useState(1);
    const { registrationStore } = useStores();

    const [isLoading, setIsLoading] = useState(false);

    const [emailError, setEmailError] = useState<string | null>(null);

    const [tokenError, setTokenError] = useState<string | null>(null);

    const [firstNameError, setFirstNameError] = useState<string | null>(null);
    const [lastNameError, setLastNameError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);

    // For Step 1
    const handleEmailSubmit = async (email: string) => {
        setIsLoading(true);
        setEmailError(null);  // Clear previous errors
        try {
            await registrationStore.setEmail(email);
            await registrationStore.verifyEmail();
            setStep(2);
        } catch (error) {
            if (Array.isArray(error?.response?.data?.email)) {
                setEmailError(error.response.data.email.join('\n'));
            } else {
                // Default error handling
                setEmailError(error.response?.data?.error || error.message || 'An unknown error occurred.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // For Step 2
    const handleTokenSubmit = async (token: string) => {
        setIsLoading(true);
        setTokenError(null);  // Clear previous errors
        try {
            await registrationStore.setToken(token);
            await registrationStore.verifyToken();
            setStep(3);
        } catch (error) {
            if (Array.isArray(error.response?.data?.token)) {
                setTokenError(error.response.data.token.join('\n'));
            } else {
                // Default error handling
                setTokenError(error.response?.data?.error || error.message || 'An unknown error occurred.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // For Step 3
    const handleFinalSubmit = async (firstName: string, lastName: string, password: string) => {
        setIsLoading(true);
        // Clear previous errors
        setFirstNameError(null);
        setLastNameError(null);
        setPasswordError(null);

        try {
            await registrationStore.setFirstName(firstName);
            await registrationStore.setLastName(lastName);
            await registrationStore.setPassword(password);
            await registrationStore.registerUser();
            // Navigate to a success page or something similar
            history.push('/dashboard'); // if using react-router
        } catch (error) {
            if (error && error.response) {
                const dataErrors = error.response.data;

                // Set individual error states based on API error response
                if (Array.isArray(dataErrors?.firstName)) setFirstNameError(dataErrors.firstName.join('\n'));
                if (Array.isArray(dataErrors?.lastName)) setLastNameError(dataErrors.lastName.join('\n'));
                if (Array.isArray(dataErrors?.password)) setPasswordError(dataErrors.password.join('\n'));
            } else {
                // Default error handling (you may want to show a global error here)
                console.log(`${error}`)
            }
        } finally {
            setIsLoading(false);
        }
    };

    const getCardTitle = () => {
        switch (step) {
            case 1: return 'Step 1: Enter Email';
            case 2: return 'Step 2: Enter Verification Token';
            case 3: return 'Step 3: Complete Registration';
            default: return '';
        }
    };

    const renderError = (error, setError) => {
        return (
            <NoticeBar
                content={error}
                color='error'
                closeable
                onClose={() => setError(null)}
            />
        )
    }

    return (
        <div className='register-container'>
            <NavBar onBack={history.goBack}>{getCardTitle()}</NavBar>
            { !!emailError && renderError(emailError, setEmailError) }
            { !!tokenError && renderError(tokenError, setTokenError) }
            { !!firstNameError && renderError(firstNameError, setFirstNameError) }
            { !!lastNameError && renderError(lastNameError, setLastNameError) }
            { !!passwordError && renderError(passwordError, setPasswordError) }

            {step === 1 && (
                <Form 
                    onFinish={({ email }) => handleEmailSubmit(email)}
                    footer={
                        <Button block type='submit' color='primary' disabled={isLoading}>
                            Submit
                        </Button>
                    }
                >
                    <Form.Item
                        name='email'
                        label='Email'
                        rules={[
                            { required: true, message: 'Please input your email!' },
                            { type: 'email', message: 'Invalid email address' },
                        ]}
                    >
                        <Input disabled={isLoading} placeholder='Email' />
                    </Form.Item>

                    <p className='login-cta'>Already created an account? <Link to='/login'>Login</Link></p>
                </Form>
            )}

            {step === 2 && (
                <Form 
                    onFinish={({ token }) => handleTokenSubmit(token)}
                    footer={
                        <Button block type='submit' color='primary' disabled={isLoading}>
                            Submit
                        </Button>
                    }
                >
                    <Form.Item
                        name='token'
                        label='Verification token'
                        rules={[
                            { required: true, message: 'Please input your verification token!' },
                        ]}
                    >
                        <Input disabled={isLoading} placeholder='Verification Token' />
                    </Form.Item>
                </Form>
            )}


            {step === 3 && (
                <Form
                    onFinish={({ firstName, lastName, password }) => handleFinalSubmit(firstName, lastName, password)}
                    footer={
                        <Button block type='submit' color='primary' disabled={isLoading}>
                            Submit
                        </Button>
                    }
                >
                    <Form.Item
                        label='First name'
                        name='firstName'
                        rules={[
                            { required: true, message: 'Please input your first name!' },
                        ]}
                    >
                        <Input disabled={isLoading} placeholder='First Name' />
                    </Form.Item>

                    <Form.Item
                        label='Last name'
                        name='lastName'
                        rules={[
                            { required: true, message: 'Please input your last name!' },
                        ]}
                    >
                        <Input disabled={isLoading} placeholder='Last Name' />
                    </Form.Item>

                    <Form.Item
                        label='Password'
                        name='password'
                        rules={[
                            { required: true, message: 'Please input your password!' },
                            { min: 6, message: 'Password must be at least 6 characters!' },
                        ]}
                    >
                        <Input disabled={isLoading} type='password' placeholder='Password' />
                    </Form.Item>
                </Form>
            )}
        </div>
    );
});
