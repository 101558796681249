import React, { useEffect, useState } from 'react';
import { Button, List } from 'antd-mobile';
import { AddOutline } from 'antd-mobile-icons'
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import './UserExams.css';
import { useStores } from '../../stores';
import { PageContent } from '../../components/PageContent';
import { UserExamForm } from '../../components/UserExamForm';
import { Spinner } from '../../components/Spinner';
import { CustomNavBar } from '../../components/CustomNavBar';

export const UserExams = observer(() => {
    const history = useHistory();
    const { userExamStore } = useStores();
    const [isFormOpen, setIsFormOpen] = useState(false);

    useEffect(() => {
        userExamStore.reset();
        return () => {
            userExamStore.reset();
        }
    }, []);

    useEffect(() => {
        (async function load() {
            await userExamStore.fetch();
        })();
    }, [userExamStore, isFormOpen]);

    const showForm = () => {
        setIsFormOpen(true);
    };

    const hideForm = () => {
        setIsFormOpen(false);
    };

    return (
        <div>
            <CustomNavBar
                title='My Exams'
                right={<Button size='small' fill='outline' onClick={showForm}><AddOutline /></Button>}
            />

            <PageContent>
                {userExamStore.loading ? (
                    <Spinner />
                ) : (
                    <List>
                        { userExamStore.userexams.map(
                            (userexam) => (
                                <List.Item
                                    key={userexam.id}
                                    description={userexam.examDate}
                                    onClick={() => history.push(`/user-exam/${userexam.id}`)}
                                >
                                    {userexam.exam.name}
                                </List.Item>
                            )
                        )}
                    </List>
                )}
            </PageContent>

            <UserExamForm
                open={isFormOpen}
                onClose={hideForm}
                onCancel={hideForm}
            />

        </div>
    );
});
