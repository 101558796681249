import React, { createContext, useContext, useState, ReactNode } from 'react';

interface StudyButtonContextProps {
    isButtonVisible: boolean;
    showButton: () => void;
    hideButton: () => void;
}

interface StudyButtonProviderProps {
    children: ReactNode;
}

const StudyButtonContext = createContext<StudyButtonContextProps | undefined>(undefined);

export const useStudyButton = (): StudyButtonContextProps => {
    const context = useContext(StudyButtonContext);
    if (!context) {
        throw new Error('useStudyButton must be used within a StudyButtonProvider');
    }
    return context;
};

export const StudyButtonProvider: React.FC<StudyButtonProviderProps> = ({ children }) => {
    const [isButtonVisible, setButtonVisibility] = useState<boolean>(true);

    const showButton = () => setButtonVisibility(true);
    const hideButton = () => setButtonVisibility(false);

    return (
        <StudyButtonContext.Provider value={{ isButtonVisible, showButton, hideButton }}>
            {children}
        </StudyButtonContext.Provider>
    );
};
