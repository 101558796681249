import React from 'react';
import { ClockCircleOutline, TextOutline } from 'antd-mobile-icons'
import { List, Space } from 'antd-mobile';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import * as DeckModel from '../../stores/Deck';
import { IconText } from '../IconText';
import './DeckListItem.css';

interface DeckListItemProps {
    deck: DeckModel.Deck;
}

export const DeckListItem: React.FC<DeckListItemProps> = observer(({ deck }) => {
    const history = useHistory();

    const handleClick = (e) => history.push(`/deck/${deck.id}`);

    return (
        <List.Item
            description={
                <Space justify='start' block style={{ '--gap': '24px' }}>
                    <IconText icon={TextOutline} text={`${deck.count} cards`} key='total-cards' />
                    <IconText icon={ClockCircleOutline} text={`${deck.due_cards_count} due`} key='due-cards' />
                </Space>
            }
            onClick={handleClick}
            arrow
        >
            {deck.title}
        </List.Item>
    )
});
