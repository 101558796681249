import React from 'react';
import { Button } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import './Hero.css';

export const Hero: React.FC = () => {
    const history = useHistory();

    const onClick = () => {
        history.push('/login')
    }

    return (
        <div className='hero'>
            <h1>Ace Your Licensing Exams</h1>
            <p>Don't let your licensing exams be a barrier to your professional growth.</p>
            <Button color='primary' onClick={onClick}>Start FREE</Button>
        </div>
    );
};
