import React from 'react';
import './UnauthLayout.css';


interface UnauthLayoutProps {
    children: React.ReactNode;
}

export const UnauthLayout: React.FC<UnauthLayoutProps> = ({ children }) => {
    return (
        <div className='unauth-layout'>
            {children}
        </div>
    );
};
