import React from 'react';
import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Home } from './pages/Home';
import ProtectedRoute from './pages/ProtectedRoute';
import { UnauthLayout } from './pages/UnauthLayout';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Cards } from './pages/Cards';
import { Dashboard } from './pages/Dashboard';
import { Deck } from './pages/Deck';
import { Decks } from './pages/Decks';
import { useInitialRootStore } from './stores';
import { Exams } from './pages/Exams';
import { ExamTopic } from './pages/ExamTopic';
import { Account } from './pages/Account';
import { Study } from './pages/Study';
import { Topics } from './pages/Topics';
import { UserExam } from './pages/UserExam';
import { UserExams } from './pages/UserExams';

const App: React.FC = observer(() => {
  const { rootStore: { authStore }, rehydrated } = useInitialRootStore(() => {
    // This runs after the root store has been initialized and rehydrated.
  })

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // You can replace with your own loading component if you wish.
  if (!rehydrated) return null

  const isAuthenticated = authStore.isAuthenticated;
  console.log({ isAuthenticated })

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/login" exact>
          <UnauthLayout>
            <Login />
          </UnauthLayout>
        </Route>
        <Route path="/register" exact>
          <UnauthLayout>
            <Register />
          </UnauthLayout>
        </Route>
        <ProtectedRoute
          path="/dashboard"
          component={Dashboard}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path="/exams"
          component={Exams}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path="/cards"
          component={Cards}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path="/decks"
          component={Decks}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path="/deck/:deckId"
          component={Deck}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path="/study"
          component={Study}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path="/account"
          component={Account}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path="/topics"
          component={Topics}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path="/user-exams"
          component={UserExams}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path="/user-exam/:userExamId"
          component={UserExam}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path="/exam-topic/:examTopicId"
          component={ExamTopic}
          isAuthenticated={isAuthenticated}
        />
      </Switch>
    </Router>
  );
});

export default App;
