import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom'
import {
    ActionSheet, Button, Card, Empty, Grid, Modal, ProgressBar, ResultPage, Space, TextArea, Toast
} from 'antd-mobile';
import { FrownFill, FrownOutline, LoopOutline, MoreOutline, SmileFill, SmileOutline } from 'antd-mobile-icons'
import type { Action } from 'antd-mobile/es/components/action-sheet'

import { useStores } from '../../stores';
import { useStudyButton } from '../../context/StudyButtonContext';
import { CustomNavBar } from '../../components/CustomNavBar';
import { StudyCard } from '../../components/StudyCard';
import { Spinner } from '../../components/Spinner';
import './Study.css';

export const Study = observer(() => {
    const { studyStore } = useStores();
    const history = useHistory();
    const [isSaving, setIsSaving] = useState(false);
    const { showButton, hideButton } = useStudyButton();
    const [flagModalVisible, setFlagModalVisible] = useState(false);
    const [flagMessage, setFlagMessage] = useState('');
    const [actionSheetVisible, setActionSheetVisible] = useState<boolean>(false);
    const actions = useMemo(() => {
        let data: Action[] = [
            {key: 'flag', text: 'Flag Card'},
            {key: 'suspend', text: 'Suspend Card'},
        ];
        if (!studyStore.stopped) {
            data.push({
                key: 'stop',
                text: 'End Study'
            })
        }
        return data;
    }, [studyStore.stopped])

    useEffect(() => {
        hideButton();

        return () => {
            showButton();
        };
    }, []);

    useEffect(() => {
        studyStore.fetchCards();
    }, [studyStore]);

    if (studyStore.loading) {
        return (
            <Spinner />
        );
    }

    const handleAction = action => {
        setActionSheetVisible(false);
        switch (action.key) {
            case 'flag':
                return openFlagModal();
            case 'suspend':
                return studyStore.suspendCurrentCard();
            case 'stop':
                return studyStore.stop();
            default:
                break;
        }
    }

    const openFlagModal = () => {
        setFlagModalVisible(true);
    };

    const handleFlagOk = () => {
        studyStore.flag(studyStore.currentCard, flagMessage);
        setFlagModalVisible(false);
    };

    const handleFlagCancel = () => {
        setFlagModalVisible(false);
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            await studyStore.save();
            Toast.show({icon: 'success', content: 'Study session saved successfully'});
            history.push('/dashboard');
        } catch (error) {
            Toast.show({icon: 'fail', content: 'Failed to save study session'});
        } finally {
            setIsSaving(false);
        }
    }

    const handleSecondaryAction = () => {
        if (isSaving) {
            return
        }
        if (studyStore.canResume) {
            studyStore.resume();
        } else {
            history.replace('/dashboard');
        }
    }

    const renderAttemptOptions = () => (
        <Space justify='center' wrap>
            <button onClick={studyStore.rateBlackout} className='study-card-action study-button blackout'><FrownFill /> Blackout</button>
            <button onClick={studyStore.rateIncorrect} className='study-card-action study-button incorrect'><FrownOutline /> Incorrect</button>
            <button onClick={studyStore.rateCorrect} className='study-card-action study-button correct'><SmileFill /> Correct</button>
            <button onClick={studyStore.rateEasy} className='study-card-action study-button easy'><SmileOutline /> Easy</button>
        </Space>
    )

    const renderFlipButton = () => (
        <button onClick={studyStore.flip} className='study-card-action study-button flip'><LoopOutline /> Reveal Answer</button>
    )

    const renderFlagModal = () => (
        <Modal
            title="Flag Card"
            visible={flagModalVisible}
            showCloseButton
            closeOnAction
            actions={[{
                key: 'ok',
                text: 'Submit',
                primary: true,
                onClick: handleFlagOk
            }]}
            onClose={handleFlagCancel}
            content={
                <div>
                    <TextArea
                        rows={4}
                        placeholder="Enter your message"
                        value={flagMessage}
                        maxLength={256}
                        onChange={value => setFlagMessage(value)}
                    />
                    <p>Kindly, tell us the reason for flagging this card or share your improvement suggestion for the card.</p>
                </div>
            }
        />
    )

    if (studyStore.stopped) {
        const levels = studyStore.recallLevels;
        return (
            <ResultPage
                status='success'
                title='Awesome!'
                description='You completed your study session!'
                primaryButtonText='Save Session'
                onPrimaryButtonClick={handleSave}
                secondaryButtonText={studyStore.canResume ? 'Resume Session' : 'Go to Dashboard'}
                onSecondaryButtonClick={handleSecondaryAction}
                details={[
                    {label: 'Cards Reviewed', value: `${studyStore.totalCardsAnswered} of ${studyStore.cards.length}`},
                    {label: 'Total Review Time', value: studyStore.totalTimeSpent}
                ]}
            >
                <div className='study-recall-breakdown-container'>
                    <Grid columns={1} gap={8}>
                        <Grid.Item>
                            <Card title='Blackout' extra={<FrownFill />} className='study-button blackout'>
                                <h2>{levels.blackout}</h2>
                            </Card>
                        </Grid.Item>
                        <Grid.Item>
                            <Card title='Incorrect' extra={<FrownOutline />} className='study-button incorrect'>
                                <h2>{levels.incorrect}</h2>
                            </Card>
                        </Grid.Item>
                        <Grid.Item>
                            <Card title='Correct' extra={<SmileFill />} className='study-button correct'>
                                <h2>{levels.correct}</h2>
                            </Card>
                        </Grid.Item>
                        <Grid.Item>
                            <Card title='Easy' extra={<SmileOutline />} className='study-button easy'>
                                <h2>{levels.easy}</h2>
                            </Card>
                        </Grid.Item>
                    </Grid>
                </div>
            </ResultPage>
        )
    }

    const renderContent = () => {
        if (studyStore.isEmpty) {
            return <Empty />
        }

        return (
            <>
                <Space direction='vertical' block>
                    <ProgressBar percent={studyStore.progress} />

                    <div className='study-card-container'>
                        <StudyCard
                            onFlip={studyStore.flip}
                            card={studyStore.currentCard}
                            flipped={studyStore.flipped}
                        />
                    </div>

                    <div className='study-card-actions-container'>
                        {
                            studyStore.flipped
                                ? renderAttemptOptions()
                                : renderFlipButton()
                        }
                    </div>
                </Space>

                { renderFlagModal() }
            </>
        )
    }

    return (
        <div>
            <CustomNavBar
                title={studyStore.title}
                right={<Button size='small' fill='outline' color='primary' onClick={() => setActionSheetVisible(true)}><MoreOutline /></Button>}
            />
            {renderContent()}
            <ActionSheet
                visible={actionSheetVisible}
                actions={actions}
                onAction={handleAction}
                onClose={() => setActionSheetVisible(false)}
            />
        </div>
    );
});
