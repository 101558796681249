// Dashboard.tsx
import React, { useEffect, useState } from 'react';
import { DotLoading, List } from 'antd-mobile'
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
    ClockCircleOutline,
    EyeOutline,
    FileOutline,
  } from 'antd-mobile-icons'

import { StudyQuery } from '../../stores/StudyStore';
import { useStores } from '../../stores';
import { CustomNavBar } from '../../components/CustomNavBar';
import './Dashboard.css';

export const Dashboard: React.FC = observer(() => {
    const { statisticStore, studyStore, userExamStore } = useStores()
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        load();
    }, [statisticStore, userExamStore.current]);

    const load = async () => {
        if (isLoading) return
        setIsLoading(true);
        try {
            await statisticStore.fetch();
        } catch (err) {
            console.debug({ err });            
        } finally {
            setIsLoading(false);
        }
    }

    const handleStudy = (query: StudyQuery) => {
        studyStore.setQuery(query);
        history.push('/study');
    }

    const statisticsDataSource = [
        {
            key: 'due',
            title: 'Due Today',
            description: 'Cards due for study today',
            value: statisticStore.due_cards_count,
            icon: <ClockCircleOutline fontSize={36} />,
            onClick: statisticStore.due_cards_count > 0 ? null : () => handleStudy({ type: 'due' } as StudyQuery)
        },
        {
            key: 'studied',
            title: 'Studied Today',
            description: 'Cards already studied today',
            value: statisticStore.attempted_cards_count,
            icon: <EyeOutline fontSize={36} />
        },
        {
            key: 'new',
            title: 'New Cards',
            description: 'New cards you haven\'t studied',
            value: statisticStore.new_cards_count,
            icon: <FileOutline fontSize={36} />
        },
    ];

    return (
        <div>
            <CustomNavBar title='Dashboard' userExamCTA />
            <List header='Activity overview'>
                {
                    statisticsDataSource.map(item => (
                        <List.Item
                            key={item.key}
                            prefix={item.icon}
                            description={item.description}
                            onClick={item.onClick || undefined}
                            clickable={item.onClick !== undefined}
                        >
                            {
                                isLoading
                                ? <DotLoading />
                                : item.value
                            }
                        </List.Item>
                    ))
                }
            </List>
        </div>
    );
});
