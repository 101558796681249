import React from 'react';

import { Header } from './Header';
import { Footer } from './Footer';
import './Home.css';
import { Content } from './Content';

export const Home = () => {

    return (
        <div className='home-container'>
            <Header />
            <Content />
            <Footer />
        </div>
    );
};
