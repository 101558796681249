import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { List, Result } from 'antd-mobile';
  
import { useStores } from '../../stores';
import { Deck } from '../../stores/Deck';
import { CustomNavBar } from '../../components/CustomNavBar';
import { DeckListItem } from '../../components/DeckListItem';
import { PageContent } from '../../components/PageContent';
import { Spinner } from '../../components/Spinner';
import './ExamTopic.css';

export const ExamTopic = observer(() => {
    const { userExamTopicStore } = useStores();
    const { examTopicId } = useParams<{ examTopicId: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const examTopic = userExamTopicStore.current;
    const decks = userExamTopicStore.decks;

    useEffect(() => {
        fetchExamTopic();
    }, [examTopicId, userExamTopicStore]);

    if (!examTopic && isLoading) {
        return (
            <Spinner />
        );
    }

    const fetchExamTopic = async () => {
        if (isLoading) return
        setIsLoading(true);
        try {
            await userExamTopicStore.fetchSingleExamTopic(examTopicId);
        } catch (err) {
            console.debug({ err });
        } finally {
            setIsLoading(false);
        }
    }

    if (!examTopic) {
        return (
            <Result
                status='error'
                title='Error'
                description='Sorry, the page you visited does not exist.'
            />
        )
    }

    return (
        <div>
            <CustomNavBar title={examTopic.topic.name} />
            <PageContent>
                <List>
                    { decks.map(
                        (deck: Deck) => <DeckListItem key={deck.id} deck={deck} />
                    )}
                </List>
            </PageContent>
        </div>
    );
});
