import { Instance, SnapshotIn, SnapshotOut, clone, types, flow } from 'mobx-state-tree';
import { fetchUserExamById, fetchUserExams, saveUserExam } from '../api';
import UserExam from './UserExam';


const UserExamStoreModel = types
    .model('UserExamStore', {
        userexams: types.array(UserExam),
        current: types.optional(types.maybeNull(UserExam), null),
        loading: types.optional(types.boolean, false),
    })
    .views((self) => ({
        get hasExam() {
            return self.userexams.length > 0;
        },
        get currentId() {
            if (self.current) {
                return self.current.id;
            }

            return self.userexams.length > 0 ? self.userexams[0].id : null;
        }
    }))
    .actions((self) => ({
        reset() {
            self.loading = false;
        },
        fetch: flow(function* () {
            if (self.loading) return;

            self.loading = true;
            try {
                const response = yield fetchUserExams();

                // Update the store
                self.userexams = response.data;
            } catch (error) {
                console.error('Failed to fetch userexams:', error);
                throw error;
            } finally {
                self.loading = false;
            }
        }),
        save: flow(function* (id, data) {
            self.loading = true;
            try {
                const response = yield saveUserExam(id, data);
                return response.data;
            } catch (error) {
                console.error(`Failed to save exam ${data}:`, error);
                throw error;
            } finally {
                self.loading = false;
            }
        }),
        fetchUserExam: flow(function* (userExamId) {
            self.loading = true;
            try {
                const response = yield fetchUserExamById(userExamId);
                self.current = response.data;
            } catch (error) {
                console.error('Failed to fetch user exam:', error);
                throw error;
            } finally {
                self.loading = false;
            }
        }),
        setCurrentById(userExamId: Number) {
            const foundExam = self.userexams.find(userExam => userExam.id === userExamId);
            if (foundExam) {
                self.current = clone(foundExam);
            }
        },
        clearCurrent() {
            self.current = null;
        },
        getById(id) {
            return self.userexams.find(item => item.id === id) || self.current;
        },
    }));

export default UserExamStoreModel;

export interface UserExamStore extends Instance<typeof UserExamStoreModel> { }
export interface UserExamStoreSnapshotIn extends SnapshotIn<typeof UserExamStoreModel> { }
export interface UserExamStoreSnapshotOut extends SnapshotOut<typeof UserExamStoreModel> { }

