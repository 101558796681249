import { Instance, SnapshotIn, SnapshotOut, types, flow } from 'mobx-state-tree';
import { fetchExamTopic } from '../api';
import Deck from './Deck';
import UserExamTopic from './UserExamTopic';


const UserExamTopicStoreModel = types
    .model('UserExamTopicStore', {
        current: types.optional(types.maybeNull(UserExamTopic), null),
        decks: types.array(Deck),
    })
    .actions((self) => ({

        fetchSingleExamTopic: flow(function* (examTopicId) {
            if (self.current?.id !== examTopicId) {
                self.current = null;
            }

            try {
                const response = yield fetchExamTopic(examTopicId);
                self.current = response.data.exam_topic;
                self.decks = response.data.decks;
            } catch (error) {
                console.error('Failed to fetch topic:', error);
                throw error;
            }
        }),

    }));

export default UserExamTopicStoreModel;

export interface UserExamTopicStore extends Instance<typeof UserExamTopicStoreModel> { }
export interface UserExamTopicStoreSnapshotIn extends SnapshotIn<typeof UserExamTopicStoreModel> { }
export interface UserExamTopicStoreSnapshotOut extends SnapshotOut<typeof UserExamTopicStoreModel> { }

