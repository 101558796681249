import React from 'react';
import { List, Space } from 'antd-mobile';
import { ClockCircleOutline, FolderOutline } from 'antd-mobile-icons'
import { observer } from 'mobx-react-lite';

import * as CardModel from '../../stores/Card';
import { IconText } from '../IconText';

import './CardListItem.css';

interface CardListItemProps {
    card: CardModel.Card;
}

export const CardListItem: React.FC<CardListItemProps> = observer(({ card }) => {
    const actions = [
        <IconText icon={FolderOutline} text={card.deck.title} key='deck' />,
    ]
    if (card.is_due) {
        actions.push(<IconText icon={ClockCircleOutline} text='Due today' key='due' />);
    }

    return (
        <List.Item
            description={
                <Space direction='vertical'>
                    {card.answer.parsedText}
                    <Space justify='start' block style={{ '--gap': '24px' }}>
                        {actions}
                    </Space>
                </Space>    
            }
        >
            {card.question.parsedText}
        </List.Item>
    )
});
