import { SnapshotOut, types } from 'mobx-state-tree';

const User = types.model({
    id: types.identifierNumber,
    email: types.string,
    firstName: types.string,
    lastName: types.string,
});

export default User;

/**
 * The data of a User.
 */
export interface UserSnapshot extends SnapshotOut<typeof User> { }
