import React from 'react';
import { Card, Divider, Grid } from 'antd-mobile';

import './Bundles.css';

const bundleData = [
    {
        title: 'PLAB 1',
        description: 'Education Tab + Subscription'
    },
    {
        title: 'PLAB 2',
        description: 'Education Tab + Subscription'
    },
    {
        title: 'AMC MCQ',
        description: 'Education Tab + Subscription'
    },
];

export const Bundles: React.FC = () => {
    return (
        <div className="bundles">
            <Divider>Supported exams</Divider>
            <Grid columns={bundleData.length} gap={8}>
                {bundleData.map((bundle, index) => (
                    <Grid.Item>
                        <Card key={index} title={bundle.title} />
                    </Grid.Item>
                ))}
            </Grid>
        </div>
    );
};
