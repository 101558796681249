import React from 'react';
import { Card as AntdCard } from 'antd-mobile';

import './StudyCard.css';
import * as CardModel from '../../stores/Card';

interface StudyCardProps {
    card: CardModel.Card;
    flipped?: Boolean;
    onFlip: () => void;
}

interface CardFaceProps {
    card: CardModel.Card;
}

const QuestionCardFace: React.FC<CardFaceProps> = ({ card }) => (
    <AntdCard bodyClassName='center-large-text'>
        {card.question.parsedText}
    </AntdCard>

);

const AnswerCardFace: React.FC<CardFaceProps> = ({ card }) => (
    <AntdCard bodyClassName='center-large-text'>
        {card.answer.parsedText}
    </AntdCard>
);

export const StudyCard: React.FC<StudyCardProps> = ({ card, flipped, onFlip }) => {

    return (
        <div onClick={onFlip} className={`card-face-container ${flipped ? 'card-face-flipped' : ''}`}>
            <div className='card-face card-question-face'>
                <QuestionCardFace card={card} />
            </div>
            <div className='card-face card-answer-face'>
                <AnswerCardFace card={card} />
            </div>
        </div>
    )
};
