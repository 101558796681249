import { types, flow } from 'mobx-state-tree';
import { verifyEmail, verifyToken, registerUser } from '../api';
import { getRootStore } from './helpers/getRootStore';

const RegistrationStore = types
    .model({
        email: types.maybe(types.string),
        token: types.maybe(types.string),
        firstName: types.maybe(types.string),
        lastName: types.maybe(types.string),
        password: types.maybe(types.string),
    })
    .actions((self) => ({
        setEmail(email: string) {
            self.email = email;
        },
        setToken(token: string) {
            self.token = token;
        },
        setFirstName(firstName: string) {
            self.firstName = firstName;
        },
        setLastName(lastName: string) {
            self.lastName = lastName;
        },
        setPassword(password: string) {
            self.password = password;
        },
        verifyEmail: flow(function* () {
            if (typeof self.email === 'undefined') {
                console.error('Email is undefined, cannot verify.');
                return;
            }

            try {
                yield verifyEmail(self.email);
            } catch (error) {
                console.error('Failed to verify email', error);
                throw error; // Propagate error
            }
        }),
        verifyToken: flow(function* () {
            if (typeof self.token === 'undefined') {
                console.error('Token is undefined, cannot verify.');
                return;
            }

            try {
                yield verifyToken({
                    email: self.email,
                    token: self.token
                });
            } catch (error) {
                console.error('Failed to verify token', error);
                throw error; // Propagate error
            }
        }),
        registerUser: flow(function* () {
            try {
                const response = yield registerUser({
                    email: self.email,
                    first_name: self.firstName,
                    last_name: self.lastName,
                    password: self.password,
                });

                // Assuming the API returns a token and user details on successful registration
                const { access_token, user } = response.data;

                // Update authentication data in authStore
                const rootStore = getRootStore(self);
                rootStore.authStore.setAuthData(access_token, {
                    id: user.id,
                    email: user.email,
                    firstName: user.first_name,
                    lastName: user.last_name,
                });
            } catch (error) {
                console.error('Failed to register user', error);
                throw error; // Propagate error
            }
        }),
    }));

export default RegistrationStore;
