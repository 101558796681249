// Account.tsx
import React from 'react';
import { Button, List, Space } from 'antd-mobile';
import { UserCircleOutline } from 'antd-mobile-icons';
import { useHistory } from 'react-router-dom';

import { PageContent } from '../../components/PageContent';
import { useStores } from '../../stores';
import './Account.css';
import { CustomNavBar } from '../../components/CustomNavBar';

export const Account: React.FC = () => {
    const { authStore } = useStores();
    const history = useHistory();
    const user = authStore.user; // Get user from authStore

    const handleLogout = () => {
        authStore.logout();
        history.replace('/login');
    };

    const handleGoToUserExams = () => {
        history.push('/user-exams');
    }

    // Guard against null user
    if (!user) {
        return <div>Loading...</div>;
    }

    const profilePhoto = 'https://via.placeholder.com/150';

    return (
        <div>
            <CustomNavBar title='Account' />

            <PageContent>
                <List>
                    <List.Item
                        prefix={<UserCircleOutline fontSize={32} color='var(--adm-color-weak)' />}
                        description={user.email}
                    >
                        {`${user.firstName} ${user.lastName}`}
                    </List.Item>
                    <List.Item
                        onClick={handleGoToUserExams}
                        clickable
                    >
                        My Exams
                    </List.Item>
                </List>
                
                <br />

                <Space block justify='center'>
                    <Button block type='submit' color='danger' fill='outline' onClick={handleLogout}>
                        Logout
                    </Button>
                </Space>
            </PageContent>
        </div>
    );
};
