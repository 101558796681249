import axios from 'axios';
import { getAuthToken, getActiveExamId } from './stores/helpers/getPersistentData';


const api = axios.create({
    baseURL: 'https://useprepped.com/api/', // 'http://localhost:8000/api/', //  
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    async config => {
        const token = await getAuthToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        const examId = await getActiveExamId()
        if (examId) {
            config.headers['X-Exam-Id'] = examId;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const verifyEmail = async (email: string) => {
    return api.post('accounts/verify-email/', { email });
};

export const verifyToken = async (tokenData: object) => {
    return api.post('accounts/verify-token/', tokenData);
};

export const registerUser = async (userData: object) => {
    return api.post('accounts/register/', userData);
};

export const login = async (email: string, password: string) => {
    return api.post('accounts/login/', { email, password });
};

export const logout = async () => {
    return api.post('accounts/logout/');
};

export const refreshToken = async (refreshToken: string) => {
    return api.post('accounts/refresh-token/', { refreshToken });
};

export const fetchPaginatedDecks = async (currentPage: number, pageSize: number = 10, topic?: number) => {
    const params = {
        page: currentPage,
        page_size: pageSize,
        topic
    };
    return api.get('decks/', { params });
};

export const createDeck = async (formData) => {
    return api.post('decks/', formData, {headers: {'Content-Type': 'multipart/form-data'}});
};

export const fetchDeckById = async (deckId) => {
    return api.get(`decks/${deckId}`);
};

export const suspendDeck = async (deckId) => {
    return api.post(`decks/${deckId}/suspend/`);
};

export const unsuspendDeck = async (deckId) => {
    return api.post(`decks/${deckId}/unsuspend/`);
};

export const updateDeckCards = async (deckId, formData) => {
    return api.put(`decks/${deckId}/save_cards/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const fetchPaginatedCards = async (currentPage: number, pageSize: number = 10) => {
    const params = {
        page: currentPage,
        page_size: pageSize,
    };
    return api.get('cards/', { params });
};

export const fetchAllSubjects = async () => {
    return api.get('subjects/');
};

export const fetchAllExams = async () => {
    return api.get('exams/');
};

export const fetchStudyCards = async (query) => {
    return api.get('cards/fetch_study_cards/', query);
};

export const saveStudy = async (data) => {
    return api.post('cards/save_card_attempts/', data);
};

export const fetchStatistic = async () => {
    return api.get('cards/statistic/');
};

export const fetchUserExams = async () => {
    return api.get('user-exams/');
};

export const suspendCard = async (cardId) => {
    return api.post(`cards/${cardId}/suspend/`);
};

export const unsuspendCard = async (cardId) => {
    return api.post(`cards/${cardId}/unsuspend/`);
};

export const flagCard = async (cardId, data) => {
    return api.post(`cards/${cardId}/flag/`, data);
};

export const saveUserExam = async (id, data) => {
    if (!!id) {
        return api.put(`user-exams/${id}/`, data);
    } else {
        return api.post('user-exams/', data);
    }
};

export const fetchUserExamById = async (id) => {
    return api.get(`user-exams/${id}`);
};

export const fetchTopicById = async (topicId) => {
    return api.get(`topics/${topicId}`);
};


export const fetchExamTopic = async (examTopicId) => {
    return api.get(`exam-topics/${examTopicId}/decks`);
}