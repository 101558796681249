import React from 'react';
import { Card, Divider, Space } from 'antd-mobile';
import './Features.css';

const featureData = [
    { 
        title: 'Top-Tier Content', 
        description: 'Crafted by leading medical experts, our content utilizes premier resources to ensure unparalleled quality and reliability.' 
    },
    { 
        title: 'Detailed Progress Tracking', 
        description: 'Boost your success rate with comprehensive reports and in-depth analysis of your study progress, helping you identify areas for improvement.' 
    },
    { 
        title: 'Customized Learning Pathways', 
        description: 'Experience a tailored learning journey with content and strategies designed to maximize retention and exam performance.' 
    },
    { 
        title: 'Automated Study Reminders', 
        description: 'Stay on top of your study schedule with customizable, automated reminders sent directly to your phone or email, ensuring consistent progress.' 
    }
];

export const Features: React.FC = () => {
    return (
        <div className="features">
            <Divider>Why Choose Us?</Divider>
            <Space direction='vertical' block>
                {featureData.map((feature, index) => (
                    <Card title={`${index + 1}. ${feature.title}`}>
                        {feature.description}
                    </Card>
                ))}
            </Space>
        </div>
    );
};
