import { applySnapshot, IDisposer, onSnapshot } from 'mobx-state-tree';
import { RootStore, RootStoreSnapshot } from '../RootStore';
import * as storage from "../../storage";

/**
 * The key we'll be saving our state as within async storage.
 */
export const ROOT_STATE_STORAGE_KEY = "root-v1"


let _disposer: IDisposer | undefined;
export const setupRootStore = async (rootStore: RootStore) => {
    let restoredState: RootStoreSnapshot | undefined | null

    try {
        // load the last known state from storage
        restoredState = ((await storage.load(ROOT_STATE_STORAGE_KEY)) ?? {}) as RootStoreSnapshot
        applySnapshot(rootStore, restoredState)
    } catch (e) {
        // if there's any problems loading, then inform the dev what happened
        console.error(e.message, null)
    }

    // stop tracking state changes if we've already setup
    if (_disposer) _disposer()

    // track changes & save to storage
    _disposer = onSnapshot(rootStore, (snapshot) => storage.save(ROOT_STATE_STORAGE_KEY, snapshot))

    const unsubscribe = () => {
        if (_disposer) {
            _disposer();
            _disposer = undefined;
        }        
    }

    return { rootStore, restoredState, unsubscribe }
};
