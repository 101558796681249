import React from 'react';
import { DotLoading } from 'antd-mobile';

import './Spinner.css';

export const Spinner = () => (
    <div className='loader-container'>
        <DotLoading />
    </div>
);
