import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Form, Input, Button, NavBar, NoticeBar } from 'antd-mobile';

import { useStores } from '../../stores';
import './Login.css';

export const Login = () => {
    const history = useHistory();  // if using react-router
    const { authStore } = useStores();

    const [emailError, setEmailError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleLoginSubmit = async (email: string, password: string) => {
        setIsLoading(true);
        setEmailError(null);
        setPasswordError(null);

        try {
            await authStore.login(email, password);
            history.push('/dashboard');  // Redirect to dashboard
        } catch (error) {
            if (Array.isArray(error?.response?.data?.email)) {
                setEmailError(error.response.data.email.join('\n'));
            } else if (Array.isArray(error?.response?.data?.password)) {
                setPasswordError(error.response.data.password.join('\n'));
            } else {
                setEmailError(error.response?.data?.error || error.message || 'An unknown error occurred.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const renderError = (error, setError) => {
        return (
            <NoticeBar
                content={error}
                color='error'
                closeable
                onClose={() => setError(null)}
            />
        )
    }
    
    return (
        <div className='login-container'>
            <NavBar back={null}>Login</NavBar>
            { !!emailError && renderError(emailError, setEmailError) }
            { !!passwordError && renderError(passwordError, setPasswordError) }
            <Form
                onFinish={({ email, password }) => handleLoginSubmit(email, password)}
                footer={
                    <Button block type='submit' color='primary' disabled={isLoading}>
                        Login
                    </Button>
                }
            >
                <Form.Item
                    name='email'
                    label='Email'
                    rules={[
                        { required: true, message: 'Please input your email!' },
                        { type: 'email', message: 'Invalid email address' }
                    ]}
                >
                    <Input placeholder='Email' disabled={isLoading} />
                </Form.Item>

                <Form.Item
                    name='password'
                    label='Password'
                    rules={[
                        { required: true, message: 'Please input your password!' }
                    ]}
                >
                    <Input placeholder='Password' type='password' disabled={isLoading} />
                </Form.Item>

                
                <p className='register-cta'>Don't have an account? <Link to='/register'>Register</Link></p>
            </Form>
        </div>
    );
};
