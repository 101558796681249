import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import TopicModel from './Topic';


const UserExamTopicModel = types.model('UserExamTopic', {
    id: types.identifierNumber,
    topic: TopicModel,
    importance: types.maybeNull(types.number),
    mastery_level: types.maybeNull(types.number),
    is_mandatory: types.maybeNull(types.boolean),
    is_studied: types.maybeNull(types.boolean),
});

export default UserExamTopicModel;

/**
 * The data of a UserExamTopic.
 */
export interface UserExamTopic extends Instance<typeof UserExamTopicModel> { }
export interface UserExamTopicSnapshotOut extends SnapshotOut<typeof UserExamTopicModel> { }
export interface UserExamTopicSnapshotIn extends SnapshotIn<typeof UserExamTopicModel> { }

