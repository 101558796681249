import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import RegistrationStore from './RegistrationStore';
import AuthStoreModel from './AuthStore';
import CardStoreModel from './CardStore';
import DeckStoreModel from './DeckStore';
import SubjectStoreModel from './SubjectStore';
import ExamStoreModel from './ExamStore';
import StudyStoreModel from './StudyStore';
import StatisticStoreModel from './StatisticStore';
import UserExamStoreModel from './UserExamStore';
import UserExamTopicStoreModel from './UserExamTopicStore';

const RootStoreModel = types
    .model('RootStore', {
        registrationStore: types.optional(RegistrationStore, {}),
        authStore: types.optional(AuthStoreModel, {}),
        cardStore: types.optional(CardStoreModel, {}),
        deckStore: types.optional(DeckStoreModel, {}),
        examStore: types.optional(ExamStoreModel, {}),
        subjectStore: types.optional(SubjectStoreModel, {}),
        studyStore: types.optional(StudyStoreModel, {}),
        statisticStore: types.optional(StatisticStoreModel, {}),
        userExamStore: types.optional(UserExamStoreModel, {}),
        userExamTopicStore: types.optional(UserExamTopicStoreModel, {}),
    });

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> { }

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> { }

export default RootStoreModel;
