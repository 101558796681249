import React, { useEffect } from 'react';
import { Card } from 'antd-mobile'
import {
    EditSOutline,
    RightOutline,
} from 'antd-mobile-icons'
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../stores';
import { Spinner } from '../../components/Spinner';
import './UserExamCTA.css';

export const UserExamCTA = observer(() => {
    const history = useHistory();
    const { userExamStore } = useStores();

    useEffect(() => {
        userExamStore.reset();
        return () => {
            userExamStore.reset();
        };
    }, [userExamStore]);

    useEffect(() => {
        (async function load() {
            await userExamStore.fetch();
        })();
    }, [userExamStore]);
    
    const onClick = () => {
        history.push('/user-exams')
    }
    
    const renderUserExamContent = () => {
        if (userExamStore.hasExam) {
            return null;
        }

        if (userExamStore.loading) {
            return <Spinner />;
        }

        return (
            <Card
                title={
                <div className='user-exam-cta-title'>
                    <EditSOutline className='user-exam-cta-title-icon'/>
                    Add your exams
                </div>
                }
                extra={<RightOutline />}
                onClick={onClick}
                className='user-exam-cta-card'
            >
                <div className='user-exam-cta-content'>
                    To get the most of the app, kindly add the exams that you are studying for. The exams are used to surface the 
                    best cards that would help ensure success in your exams.
                </div>
            </Card>
        )
    };

    return (
        <div className='user-exam-cta-container'>
            {renderUserExamContent()}
        </div>
    )
});
